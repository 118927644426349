import React from "react";
import { Grid, Box, Text } from "@chakra-ui/react";
import DataExecutionFees from "../api/DataExecutionFees";
import DataCollateralUsed from "../api/DataCollateralUsed";
import DataRatio from "../api/DataRatio";
//import DataDynamicCollateral from "../api/DataDynamicCollateral";
import DataCompound from "../api/DataCompound";
import DataStartingValue from "../api/DataStartingValue";
import DataCollarStatus from "../api/DataCollarStatus";
import DataCallerBalances from "../api/DataCallerBalances";
import DataUsdcBalance from "../api/DataUsdcBalance";
import DataDeltaProfits from "../api/DataDeltaProfits";

const AccountDataGrid = () => {
  return (
    <Grid
  templateColumns={{
    base: "repeat(1, 1fr)", // Single column for small screens
    sm: "repeat(2, 1fr)",   // Two columns for small devices
    md: "repeat(2, 1fr)",   // Two columns for medium devices
    lg: "repeat(3, 1fr)",   // Three columns for large devices
  }}
  gap={4}
  p={4}
  //overflowX="auto" // Allow horizontal scrolling if needed
>
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Status
    </Text>
    <DataCollarStatus />
  </Box>

  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Delta Profit
    </Text>
    <DataDeltaProfits/>
  </Box>
  
  {/* Gas Balance */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Gas Balance
    </Text>
    <DataCallerBalances />
  </Box>

  {/* USDC Balance */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      USDC Balance
    </Text>
    <DataUsdcBalance />
  </Box>

  {/* Execution Fees */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Execution Fees
    </Text>
    <DataExecutionFees />
  </Box>

  {/* Collateral Spending */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Collateral Spending
    </Text>
    <DataCollateralUsed />
  </Box>

  {/* Status */}
  

  {/* Ratio */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Ratio
    </Text>
    <DataRatio />
  </Box>

  {/* Starting Position Value */}
  <Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Starting Position Value
    </Text>
    <DataStartingValue />
  </Box>

  {/* Compound Events */}
  {/**<Box>
    <Text fontSize="md" fontWeight="bold" mb={2}>
      Compound Events
    </Text>
    {/**<DataCompound />
    <DataCollarRuntime/>
  </Box> */}
  
</Grid>

  );
};

export default AccountDataGrid;
