import React from 'react';
import { Box, Heading, Text, Link, Stack, IconButton, useToast, Flex } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const ContractList = () => {
  const toast = useToast();

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    toast({
      title: "Copied to clipboard",
      description: `${address}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const contracts = [
    { name: "Blindfold Wallet Factory", address: "0x61b4CdDEF175375b1A582A1c9d7cD3c89EF7F21E" },
    { name: "Position Router", address: "0xfff6d276bc37c61a23f06410dce4a400f66420f8" },
    { name: "Router", address: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8" },
    { name: "Order Book", address: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5" },
    { name: "Reader", address: "0x67b789D48c926006F5132BFCe4e976F0A7A63d5D" },
    { name: "Vault", address: "0x9ab2De34A33fB459b538c43f251eB825645e8595" },
    { name: "Vault Price Feed", address: "0x27e99387af40e5CA9CE21418552f15F02C8C57E7" },
  ];

  return (
    <Box
      p={{ base: 4, md: 6 }}
      maxW="container.md"
      mx="auto"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      bg="white"
    >
      <Heading size='md' textAlign="center" mb={4}>
        Contracts
      </Heading>

      <Stack spacing={4}>
        {contracts.map((contract, index) => (
          <Flex
            key={index}
            direction={{ base: "column", sm: "row" }}
            align="center"
            p={3}
            borderWidth="1px"
            borderRadius="md"
            bg="gray.50"
            justify="space-between"
            gap={2} // Adds spacing between elements
          >
            {/* Contract Name Column */}
            <Box flex={{ base: "1", sm: "0.3" }} textAlign={{ base: "center", sm: "left" }}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                {contract.name}:
              </Text>
            </Box>

            {/* Contract Address Column */}
            <Box
              flex="1"
              wordBreak="break-all"
              textAlign={{ base: "center", sm: "left" }}
              fontFamily="monospace"
              fontSize={{ base: "sm", md: "md" }}
            >
              <Text>{contract.address}</Text>
            </Box>

            {/* Copy Button Column */}
            <Box flex={{ base: "none", sm: "0.1" }} textAlign="right">
              <IconButton
                icon={<CopyIcon />}
                size="sm"
                onClick={() => handleCopy(contract.address)}
                aria-label={`Copy ${contract.name}`}
              />
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default ContractList;
