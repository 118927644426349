import React, { useState, useEffect } from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataWavaxRsi = () => {
  const [rsiData, setRsiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://blindfoldcapital.com/api/ta/trade_signals";

  const fetchRsiValues = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();

      // Extract and sort RSI data by timestamp (oldest → newest)
      const rsiEntries = result.data
        .filter((entry) => entry.rsi !== null)
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)); // Sort by ascending timestamp

      setRsiData(rsiEntries); // Maintain natural order (oldest → newest)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRsiValues();

    // Fetch data every 2 minutes
    const intervalId = setInterval(fetchRsiValues, 120000);

    return () => clearInterval(intervalId); // Cleanup interval
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text>Error: {error}</Text>;

  // Most recent RSI value
  const mostRecentRsi = rsiData.length > 0 ? rsiData[rsiData.length - 1] : null;

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago",
    });
  };

  const mostRecentRsiValue = mostRecentRsi ? mostRecentRsi.rsi.toFixed(2) : "N/A";
  const mostRecentRsiTimestamp = mostRecentRsi
    ? formatDate(mostRecentRsi.timestamp)
    : "N/A";

  // Prepare chart data (oldest → newest)
  const chartLabels = rsiData.map((entry) => formatDate(entry.timestamp));
  const chartDataValues = rsiData.map((entry) => entry.rsi);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "RSI Values",
        data: chartDataValues,
        borderColor: "orange",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        tension: 0.3, // Smooth line
        fill: true,
        pointRadius: 3,
        pointHoverRadius: 6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `RSI: ${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          maxTicksLimit: 12, // Reduce x-axis ticks for readability
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "RSI Value",
        },
        ticks: {
          callback: (value) => value.toFixed(2),
        },
      },
    },
  };

  return (
  <Box
            p={2}
            backgroundColor="gray.50"
            borderRadius="md"
            boxShadow="md"
          >
      {mostRecentRsi && (
        <Box >
          <Text fontSize="sm">
            <strong>Most Recent RSI:</strong> {mostRecentRsiValue} (as of {mostRecentRsiTimestamp})
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataWavaxRsi;
