import React, { useEffect, useState } from "react";
import axios from "axios";
import { Text } from "@chakra-ui/react";

export default function AvaxCoinGeckoPrice({ amount }) {
  const [currentPrice, setCurrentPrice] = useState(null);
  const [totalValue, setTotalValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://api.coingecko.com/api/v3/coins/markets", {
          params: {
            vs_currency: "usd",
            ids: "avalanche-2",
          },
        });
        const price = response.data[0]?.current_price;

        if (price !== undefined && !isNaN(amount)) {
          setCurrentPrice(price);
          setTotalValue(price * amount);
        } else {
          throw new Error("Invalid price data or amount");
        }
      } catch (error) {
        console.error("Error fetching current Avalanche price:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (amount != null && !isNaN(amount)) {
      fetchCurrentPrice();
    } else {
      setError(new Error("Invalid amount"));
      setLoading(false);
    }
  }, [amount]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <div>
      <Text fontSize="sm">${totalValue.toFixed(2)} USD</Text>
    </div>
  );
}
