import React from "react";
import {
  Box,
  SimpleGrid,
  HStack,
  VStack,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

const PositionsGrid = ({
  positionsDetails,
  collateralTokens,
  indexTokens,
  prices,
  tokenImages,
  wavax,
  usdc,
  usdc_e,
  weth,
  wbtc,
  btc,
  showDetails,
  toggleDetails,
  formatAddress,
  sellLimit,
  _lossLimit,
  totalPositions,
  totalCollateral,
  utilizationRate,
  usdcBalance,
  handleReload,
}) => {
  return (
    <SimpleGrid
      columns={{ base: 2, sm: 2, md: 2 }} // Ensure 2 columns on mobile
      spacing={4} // Spacing between cards
    >
      {positionsDetails.map((position) => {
        const collateralTokenName =
          collateralTokens[position.positionIndex] === wavax
            ? "wavax"
            : collateralTokens[position.positionIndex] === usdc
            ? "usdc"
            : collateralTokens[position.positionIndex] === usdc_e
            ? "usdc_e"
            : collateralTokens[position.positionIndex] === weth
            ? "weth"
            : collateralTokens[position.positionIndex] === wbtc
            ? "wbtc"
            : collateralTokens[position.positionIndex] === btc
            ? "btc"
            : "unknown";

        const indexTokenName =
          indexTokens[position.positionIndex] === wavax
            ? "wavax"
            : indexTokens[position.positionIndex] === usdc
            ? "usdc"
            : indexTokens[position.positionIndex] === weth
            ? "weth"
            : indexTokens[position.positionIndex] === wbtc
            ? "wbtc"
            : indexTokens[position.positionIndex] === btc  // Corrected here
            ? "btc"
            : "unknown";

        const indexTokenAddress =
          indexTokens[position.positionIndex] === wavax
            ? "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
            : indexTokens[position.positionIndex] === usdc
            ? "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664"
            : indexTokens[position.positionIndex] === weth
            ? "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
            : indexTokens[position.positionIndex] === wbtc
            ? "0x152b9d0FdC40C096757F570A51E494bd4b943E50"
            : "unknown";

        const tokenImage1 = tokenImages[collateralTokenName] || "./avax.png";
        const tokenImage2 = tokenImages[indexTokenName] || "./avax.png";

        const collateralTokenDisplayName =
          collateralTokenName === "usdc_e"
            ? "USDC.e"
            : collateralTokenName.toUpperCase();
        const indexTokenDisplayName =
          indexTokenName === "usdc" ? "USDC.e" : indexTokenName.toUpperCase();

        const positionType = position.isLong ? "Long" : "Short"; // Determine position type

        return (
          <Box w="100%" h='100%' justifyItems="center" key={position.positionIndex}>
            <Box
              w="145px"
              p={1}
              fontSize='sm'
              bg="white"
              //shadow="lg"
              borderWidth="1px"
              borderRadius="md"
            >
              
              <HStack gap="2px" p={0}  justify='center'>
                
                <Text
                  
                  textAlign="center"
                  color={position.hasProfit === "1" ? "green.500" : "red.500"}
                >
                  {position.deltaPercentage}
                </Text>
                <Text
                  
                  textAlign="center"
                  color={position.hasProfit === "1" ? "green.500" : "red.500"}
                >
                  (${position.delta})
                </Text>
              </HStack>

              
              <HStack gap="2px" mt={1}>
                {/**<img
                  src={tokenImage1}
                  alt={collateralTokenName}
                  style={{ width: "11px", marginRight: "0px" }}
                />
                <Text>/</Text> */}
                <img
                  src={tokenImage2}
                  alt={indexTokenName}
                  style={{
                    width: "16px",
                    marginLeft: "0px",
                    marginRight: "1px",
                  }}
                />
                <Text >{indexTokenDisplayName}</Text>

                
              </HStack>
              
              <Box  >
              <Text>
                Type: {positionType}
                
              </Text>
                <Text>Size: ${position.size}</Text>
                <Text>Collateral: ${position.collateral}</Text>
              </Box>

              {/* Toggle Details Button */}
              <HStack justify="center" mt={-1}>
                <IconButton
                  icon={showDetails ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={toggleDetails}
                  aria-label="Toggle Position Details"
                  size="sm"
                  variant="unstyled"
                />
              </HStack>

              {/* Position Details (conditionally rendered) */}
              {showDetails && (
                <Box  >
                  <HStack gap='2px'>
                    <Text>
                      Pair: {collateralTokenDisplayName}/{indexTokenDisplayName}
                    </Text>
                  </HStack>

                  <Text>Leverage: {position.leverage}x</Text>
                  <Text  >
                  Price: ${prices[position.positionIndex]}
                </Text>
                  <Text>Entry Price: {position.entryPrice}</Text>
                  <Text>Sell Limit: {sellLimit} %</Text>
                  <Text>Buy Limit: {_lossLimit} %</Text>
                  <Text>Target Sell: ${position.sellPrice}</Text>
                  <Text>Target Buy: ${position.buyPrice}</Text>
                  <Text>Fees: {position.openClose}</Text>
                  <Text>Trade Value: ${position.potential}</Text>
                  <Text fontSize='xs'>Last: {position.lastIncreasedTime}</Text>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default PositionsGrid;
