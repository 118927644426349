import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Box, Text, Spinner } from "@chakra-ui/react";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataTokenPriceBtc = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/token_prices/";

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // Filter for BTC and within the last 48 hours
      const twoDaysAgo = Date.now() - 48 * 60 * 60 * 1000;

      const btcData = result.data
        .filter((entry) => entry.token_name === "BTC")
        .filter((entry) => new Date(entry.timestamp).getTime() >= twoDaysAgo);

      setData(btcData.reverse());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000); // Refresh every 2 minutes
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Text>Loading BTC Chart...</Text>;

  if (error) {
    return (
      <Box textAlign="center" p={4}>
        <Text color="red.500">Error: {error}</Text>
      </Box>
    );
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago",
    });
  };

  const mostRecentEntry = data.length > 0 ? data[data.length - 1] : null;
  const mostRecentPrice = mostRecentEntry ? mostRecentEntry.price : "N/A";
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : "N/A";

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const btcPrices = data.map((entry) => entry.price);

  const chartData = {
    labels,
    datasets: [
      {
        label: "BTC Price",
        data: btcPrices,
        borderColor: "orange",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          maxTicksLimit: 12,
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Price (USD)",
        },
      },
    },
  };

  return (
    <Box p={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {mostRecentEntry && (
        <Box mb={2}>
          <Text fontSize="sm">
            <strong>BTC Price:</strong> ${mostRecentPrice.toFixed(2)}
          </Text>
          <Text fontSize="sm">
            <strong>Timestamp:</strong> {mostRecentTimestamp}
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataTokenPriceBtc;
