import React, { useContext, useState } from "react";
import { ethers } from "ethers";
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import JakobWallet from "../../contracts/JakobWallet.json"; // Importing the contract ABI and address
import { AppContext } from "../../AppContext";

const TransferAvaxToPaymaster = () => {
  const { rpcUrl, account, contractAddress } = useContext(AppContext);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const toast = useToast();

  // Extract the contract address and ABI from the JakobWallet import
  //const contractAddress = JakobWallet.address; // JakobWallet contract address
  const contractAbi = JakobWallet.abi; // JakobWallet ABI

  // Get the provider (e.g., MetaMask) and signer (connected account)
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  // Create a contract instance
  const contract = new ethers.Contract(contractAddress, contractAbi, signer);

  // Function to handle the transfer
  const handleTransfer = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      // Convert input amount to wei
      const amountInWei = ethers.utils.parseEther(amount);

      // Call the contract function
      const tx = await contract.transferAvaxToPaymaster(amountInWei);

      // Wait for transaction to be mined
      await tx.wait();
      setSuccess(`Transfer of ${amount} AVAX to paymaster was successful!`);
      toast({
        title: "Transfer Successful",
        description: `Transferred ${amount} AVAX to the paymaster.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setError(`Error: ${err.message}`);
      toast({
        title: "Transfer Failed",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={5} bg='ghostwhite' shadow="md" borderWidth="1px" borderRadius="md">
      <VStack spacing={4}>
        <Text fontSize="14px">Transfer AVAX to Paymaster</Text>

        <Input
          placeholder="Enter amount in AVAX"
          value={amount}
          bg='white'
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />

        <Button
          colorScheme="blue"
          onClick={handleTransfer}
          isLoading={loading}
          isDisabled={!amount || loading}
        >
          Transfer AVAX
        </Button>

        
        <Box maxWidth='350px'>
        {error && <Text color="red.500">{error}</Text>}
        {success && <Text color="green.500">{success}</Text>}
        </Box>
      </VStack>
    </Box>
  );
};

export default TransferAvaxToPaymaster;
