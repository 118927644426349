import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { AppContext } from "../../AppContext";
import JakobWallet from "../../contracts/JakobWallet.json";

const PaymasterLimits = () => {
  const { rpcUrl, contractAddress } = useContext(AppContext);
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const [dailyLimit, setDailyLimit] = useState("");
  const [dailyTotal, setDailyTotal] = useState("");

  // Fetch the daily limit from the contract
  const fetchDailyLimit = async () => {
    try {
      const JakobWalletContract = new ethers.Contract(
        contractAddress,
        JakobWallet.abi,
        provider
      );
      const dailyLimit = await JakobWalletContract.dailyLimit();
      const dl = ethers.utils.formatEther(dailyLimit);
      setDailyLimit(dl);
    } catch (error) {
      console.error("Error fetching daily limit:", error);
    }
  };

  // Fetch the daily transfer total from the contract
  const fetchDailyTotal = async () => {
    try {
      const JakobWalletContract = new ethers.Contract(
        contractAddress,
        JakobWallet.abi,
        provider
      );
      const dailyTotal = await JakobWalletContract.dailyTransferTotal();
      const dl = ethers.utils.formatEther(dailyTotal);
      setDailyTotal(dl);
    } catch (error) {
      console.error("Error fetching daily transfer total:", error);
    }
  };

  useEffect(() => {
    fetchDailyLimit();
    fetchDailyTotal();
  }, [contractAddress, provider, JakobWallet]);

  return (
    <div>
      <p>Daily Limit: {dailyLimit} AVAX</p>
      <p>Daily Transfer Total: {dailyTotal} AVAX</p>
    </div>
  );
};

export default PaymasterLimits;
