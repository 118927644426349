import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    IconButton,
    Button,
    useDisclosure,
    Tooltip
  } from '@chakra-ui/react';
  import { FaUsers } from 'react-icons/fa'; // Import team icon
  import ApprovalSection from './ApprovalSection';
  
  const ApprovalModal = ({
    owners,
    paymasterAddress,
    paymasterBalance,
    loading,
    error,
    handleApproval,
    formatAddress
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    return (
      <>
          <Tooltip
            label="Owners"
            aria-label="Owners Tooltip"
            placement="top"
          >
        <IconButton
          icon={<FaUsers />}
          aria-label="Open Approval Modal"
          onClick={onOpen}
          //colorScheme="blue"
          size="lg"
          variant='unstyled'
        />
  </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Owner Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={1}>
              <ApprovalSection
                owners={owners}
                paymasterAddress={paymasterAddress}
                paymasterBalance={paymasterBalance}
                loading={loading}
                error={error}
                handleApproval={handleApproval}
                formatAddress={formatAddress}
              />
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default ApprovalModal;
  