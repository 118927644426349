import { VStack } from '@chakra-ui/react';
import OwnerApprovalBox from './OwnerApprovalBox';
import PaymasterInfoBox from './PaymasterInfoBox';

const ApprovalSection = ({
  owners,
  paymasterAddress,
  paymasterBalance,
  loading,
  error,
  handleApproval,
  formatAddress
}) => {
  return (
    <VStack gap={2} justify="center" textAlign="center">
      {owners.map((owner, index) => (
        <OwnerApprovalBox
          key={index}
          ownerName={`Owner ${index + 1}`}
          ownerAddress={owner.address}
          isApproved={owner.isApproved}
          handleApproval={handleApproval}
        />
      ))}
      <PaymasterInfoBox
        paymasterAddress={paymasterAddress}
        paymasterBalance={paymasterBalance}
        loading={loading}
        error={error}
      />
    </VStack>
  );
};

export default ApprovalSection;
