import React, { useState, useEffect } from "react";
import { Box, Text, Spinner, Flex } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataIndicators = () => {
  const [fgiData, setFgiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/indicators/";

  const fetchFgiValues = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const fgiEntries = result.data.filter(
        (indicator) => indicator.name === "Fear and Greed Index"
      );
      setFgiData(fgiEntries);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFgiValues();
  }, []);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  // Get the most recent FGI entry
  const mostRecentFgi = fgiData.length > 0 ? fgiData[fgiData.length - 1] : null;
  const mostRecentFgiValue = mostRecentFgi ? mostRecentFgi.value : "N/A";
  const mostRecentFgiTimestamp = mostRecentFgi
    ? new Date(mostRecentFgi.timestamp).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "N/A";

  // Prepare chart data
  const chartLabels = fgiData.map((entry) =>
    new Date(entry.timestamp).toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
  const chartDataValues = fgiData.map((entry) => entry.value);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Fear and Greed Index",
        data: chartDataValues,
        fill: false,
        borderColor: "green",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date/Time",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "FGI Value",
        },
      },
    },
  };

  return (
    <Box p={4}>
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <h2>
          FGI: {mostRecentFgiValue} (as of {mostRecentFgiTimestamp})
        </h2>
        
      </Flex>
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default DataIndicators;
