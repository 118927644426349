import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Box, HStack, Text } from "@chakra-ui/react";
import AvaxCoinGeckoPrice from "../components/coinGecko/AvaxCoinGeckoPrice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataCallerBalances = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Update the API URL to fetch from caller_balances
  const apiUrl = "https://blindfoldcapital.com/api/paymaster_balance/";

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);
      const formattedDate = oneDayAgo.toISOString();

      // Add query parameter for filtering by date (if supported)
      const urlWithParams = `${apiUrl}?since=${formattedDate}`;
      const response = await fetch(urlWithParams);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // Filter data client-side in case the API does not support query parameters
      const filteredData = result.data.filter((entry) => {
        const entryDate = new Date(entry.timestamp);
        return entryDate >= oneDayAgo;
      });

      setData(filteredData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const labels = [...new Set(data.map((entry) => formatDate(entry.timestamp)))];

  const avaxEntries = data.filter((entry) => entry.token === "AVAX");

  const avaxData = avaxEntries.map((entry) => entry.balance);

  const mostRecentAvaxEntry = avaxEntries[avaxEntries.length - 1] || null;

  const mostRecentAvaxBalance = mostRecentAvaxEntry
    ? mostRecentAvaxEntry.balance.toFixed(2)
    : "N/A";

  const mostRecentAvaxTimestamp = mostRecentAvaxEntry
    ? formatDate(mostRecentAvaxEntry.timestamp)
    : "N/A";

  const chartOptions = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value.toFixed(2);
          },
        },
      },
    },
  };

  const createChartData = (tokenData, tokenLabel, color) => ({
    labels: labels,
    datasets: [
      {
        label: tokenLabel,
        data: tokenData,
        fill: false,
        borderColor: color,
        tension: 0.1,
      },
    ],
  });

  return (
    <Box
      p={2}
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="md"
    >
      <HStack>
        <Text fontSize="sm">{mostRecentAvaxBalance} AVAX</Text>
        <AvaxCoinGeckoPrice amount={mostRecentAvaxBalance} />
        {mostRecentAvaxTimestamp && (
          <Text fontSize="sm">Last Update: {mostRecentAvaxTimestamp}</Text>
        )}
      </HStack>

      <Line
        data={createChartData(avaxData, "AVAX", "red")}
        options={chartOptions}
      />
    </Box>
  );
};

export default DataCallerBalances;
