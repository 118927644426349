import React, { useState, useEffect } from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataWavaxEmaFast = () => {
  const [emaFastData, setEmaFastData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://blindfoldcapital.com/api/ta/trade_signals";

  const fetchEmaFastValues = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();

      // Extract all EMA Fast data and sort chronologically (oldest → newest)
      const emaFastEntries = result.data
        .filter((entry) => entry.ema_fast !== null)
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      setEmaFastData(emaFastEntries);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmaFastValues();

    // Fetch data every 2 minutes
    const intervalId = setInterval(fetchEmaFastValues, 120000);

    return () => clearInterval(intervalId); // Cleanup interval
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text>Error: {error}</Text>;

  // Most recent EMA Fast value
  const mostRecentEmaFast = emaFastData.length > 0 ? emaFastData[emaFastData.length - 1] : null;

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago",
    });
  };

  const mostRecentEmaFastValue = mostRecentEmaFast
    ? mostRecentEmaFast.ema_fast.toFixed(2)
    : "N/A";

  const mostRecentEmaFastTimestamp = mostRecentEmaFast
    ? formatDate(mostRecentEmaFast.timestamp)
    : "N/A";

  // Prepare chart data (oldest → newest)
  const chartLabels = emaFastData.map((entry) => formatDate(entry.timestamp));
  const chartDataValues = emaFastData.map((entry) => entry.ema_fast);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "EMA Fast Values",
        data: chartDataValues,
        borderColor: "blue",
        backgroundColor: "rgba(0, 123, 255, 0.2)",
        tension: 0.3, // Smooth line
        fill: true,
        pointRadius: 3,
        pointHoverRadius: 6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `EMA Fast: ${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          maxTicksLimit: 12, // Reduce x-axis ticks for readability
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "EMA Fast Value",
        },
        ticks: {
          callback: (value) => value.toFixed(2),
        },
      },
    },
  };

  return (
    <Box
              p={2}
              backgroundColor="gray.50"
              borderRadius="md"
              boxShadow="md"
            >
      {mostRecentEmaFast && (
        <Box>
          <Text fontSize="sm">
            <strong>Most Recent EMA Fast:</strong> {mostRecentEmaFastValue} (as of {mostRecentEmaFastTimestamp})
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataWavaxEmaFast;
