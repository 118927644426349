import React, { useState, useEffect } from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const DataWavaxSma = () => {
  const [smaData, setSmaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/wavax_sma/";

  const fetchSmaValues = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const result = await response.json();

      // Filter for "WAVAX SMA"
      const smaEntries = result.data.filter(
        (indicator) => indicator.name === "WAVAX SMA"
      );

      // Filter for the last 6 hours
      const sixHoursAgo = Date.now() - 6 * 60 * 60 * 1000;
      const filteredData = smaEntries.filter(
        (entry) => new Date(entry.timestamp).getTime() >= sixHoursAgo
      );

      // Reverse to have most recent data on the right
      setSmaData(filteredData.reverse());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSmaValues();
    // Fetch data every 2 minutes
    const intervalId = setInterval(fetchSmaValues, 120000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text>Error: {error}</Text>;

  // Extract the most recent BUY or SELL signal
  const mostRecentBuySellSignal = smaData.find(
    (entry) => entry.value === "BUY" || entry.value === "SELL"
  );

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      year: "2-digit", // Two-digit year
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago", // Use Central Time
    });
  };

  const mostRecentBuySellInfo = mostRecentBuySellSignal
    ? `${mostRecentBuySellSignal.value} at ${formatDate(
        mostRecentBuySellSignal.timestamp
      )}`
    : "No BUY or SELL signals in the last 6 hours";

  // Extract the most recent signal (regardless of type)
  const mostRecentSignal = smaData.length > 0 ? smaData[smaData.length - 1] : null;
  const mostRecentSignalValue = mostRecentSignal ? mostRecentSignal.value : "N/A";
  const mostRecentSignalTimestamp = mostRecentSignal
    ? formatDate(mostRecentSignal.timestamp)
    : "N/A";

  // Prepare chart data
  const chartLabels = smaData.map((entry) => formatDate(entry.timestamp));
  const chartDataValues = smaData.map((entry) => {
    // Map signal values to numerical values for chart
    if (entry.value === "BUY") return 1;
    if (entry.value === "SELL") return -1;
    return 0; // For "HOLD" or other values
  });

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "SMA Signals (Last 6 Hours)",
        data: chartDataValues,
        borderColor: "purple",
        backgroundColor: "rgba(128, 0, 128, 0.2)",
        tension: 0.3, // Smooth line
        fill: true,
        pointBackgroundColor: chartDataValues.map((value) =>
          value === 1 ? "green" : value === -1 ? "red" : "blue"
        ),
        pointRadius: 5,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const signal =
              value === 1 ? "BUY" : value === -1 ? "SELL" : "HOLD";
            return `Signal: ${signal}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          maxTicksLimit: 12,
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            if (value === 1) return "BUY";
            if (value === -1) return "SELL";
            return "HOLD";
          },
        },
        title: {
          display: true,
          text: "Signal",
        },
        min: -1.5,
        max: 1.5,
        beginAtZero: false,
      },
    },
  };

  return (
    <Box
      width="100%"
      height={{ base: "300px", md: "400px" }}
      p={4}
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="md"
    >
      <Box mb={2}>
        <Text>
          Most Recent BUY/SELL Signal: {mostRecentBuySellInfo}
        </Text>
        <Text>
          Current Signal: {mostRecentSignalValue} (as of {mostRecentSignalTimestamp})
        </Text>
      </Box>
      <Box width="100%" height="100%">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataWavaxSma;
