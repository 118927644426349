import React from "react";
import { Box, Text, Heading, VStack } from "@chakra-ui/react";

const IndicatorDescription = () => {
  return (
    <Box
      p={{ base: 2, md: 4 }} // Smaller padding on mobile, standard on larger screens
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="sm"
      maxW="100%"
      mx="auto"
    >
      <Heading
        as="h3"
        size={{ base: "sm", md: "md" }} // Smaller heading on mobile
        mb={{ base: 2, md: 4 }}
        textAlign="center"
      >
        Indicator Descriptions
      </Heading>
      <VStack
        align="start"
        spacing={{ base: 2, md: 3 }} // Smaller spacing between texts on mobile
        fontSize={{ base: "xs", md: "sm" }} // Smaller font size on mobile
      >
        <Text>
          <strong>RSI (Relative Strength Index):</strong> Measures the speed and change of price movements. 
          Values above <strong>70</strong> indicate overbought conditions, while values below <strong>30</strong> indicate oversold conditions.
        </Text>
        <Text>
          <strong>EMA Fast (Exponential Moving Average Fast):</strong> Tracks recent price trends more closely, making it responsive to quick market changes.
        </Text>
        <Text>
          <strong>EMA Slow (Exponential Moving Average Slow):</strong> Averages price data over a longer period, providing a smoother trendline.
        </Text>
        <Text>
          <strong>VWAP (Volume Weighted Average Price):</strong> Indicates the average price a security has traded at throughout the day, based on volume and price.
        </Text>
        <Text>
          <strong>Signal:</strong> Provides a market action suggestion, such as <strong>Buy</strong>, <strong>Sell</strong>, or <strong>Neutral</strong>, based on indicator analysis.
        </Text>
        <Text>
          <strong>Direction:</strong> Indicates the general trend, such as <strong>Uptrend</strong>, <strong>Downtrend</strong>, or <strong>Sideways</strong>.
        </Text>
        <Text>
          <strong>Leverage (Long/Short):</strong> Suggests recommended leverage values for long and short positions based on the current market conditions.
        </Text>
      </VStack>
    </Box>
  );
};

export default IndicatorDescription;
