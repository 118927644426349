import { Box, HStack, Text, Spacer, Tooltip } from "@chakra-ui/react";
import ApprovalIconButton from "./ApprovalIconButton";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";
import { formatAddress } from "../../utils/formatMetamask";

const OwnerApprovalBox = ({ ownerName, ownerAddress, isApproved, handleApproval }) => (
  <Box p={1} bg="white" shadow="md" borderWidth="1px" borderRadius="md" w="100%">
    <HStack w="100%">
      {/* Display a green or red dot based on approval status */}
      <Box w={3} h={3} borderRadius="full" bg={isApproved ? "green.500" : "red.500"} />

      <Text fontSize="12px">
        {ownerName}: {formatAddress(ownerAddress)} <CopyToClipboardButton value={ownerAddress} />
      </Text>

      {/* Display "Locked" if not approved and the dot is red */}
      {!isApproved && (
        <Text color="red.500" fontSize="12px" ml={2}>
          Locked
        </Text>
      )}

      {/* Display "Locked" if not approved and the dot is red */}
      {isApproved && (
        <Text color="green.500" fontSize="12px" ml={2}>
          Unlocked
        </Text>
      )}

      <Spacer />
      <Tooltip label="Approve Transfer" aria-label="Approve Transfer Tooltip">
        <ApprovalIconButton handleApproval={handleApproval} />
      </Tooltip>
    </HStack>
  </Box>
);

export default OwnerApprovalBox;
