import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataTokenPriceWavax = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    setLoading(true); // Show loading indicator while fetching
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Network response was not ok');

      const result = await response.json();

      // Filter for WAVAX token
      const wavaxData = result.data.filter((entry) => entry.token_name === 'WAVAX');

      // Filter for only the last 6 hours
      const sixHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

      const filteredData = wavaxData.filter((entry) => {
        const entryDate = new Date(entry.timestamp);
        return entryDate >= sixHoursAgo;
      });

      // Reverse for most recent on the right and set state
      setData(filteredData.reverse());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount

    // Optimize interval fetch to 2 minutes for fast updates
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  if (loading) return <Text>Loading AVAX Chart...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: '2-digit', // Two-digit year
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago', // Use Central Standard Time
    });
  };

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const wavaxPrices = data.map((entry) => entry.price);

  // Most recent price
  const mostRecentPrice = data.length > 0 ? data[data.length - 1].price : null;
  const mostRecentTimestamp = data.length > 0 ? formatDate(data[data.length - 1].timestamp) : null;

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'WAVAX Price (Last 24 Hours)',
        data: wavaxPrices,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        tension: 0.3, // Smooth line
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Adjust height dynamically
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toFixed(2)}`, // Format tooltip price
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: { maxTicksLimit: 12 }, // Reduce X-axis labels for readability
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (USD)',
        },
      },
    },
  };

  return (
    <Box
          p={2}
          backgroundColor="gray.50"
          borderRadius="md"
          boxShadow="md"
        >
      {mostRecentPrice && (
        <Box marginBottom={1}>
          <Text fontSize="sm">
          <strong>Most Recent AVAX Price:</strong>
            ${mostRecentPrice.toFixed(2)} (as of {mostRecentTimestamp})
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px" mb={2} >
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataTokenPriceWavax;
