import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataTokenPriceWeth = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/token_prices/';

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      const wethData = result.data.filter((entry) => entry.token_name === 'WETH');

      // Filter for the last 24 hours
      const fortyEightHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
      const filteredData = wethData.filter(
        (entry) => new Date(entry.timestamp).getTime() >= fortyEightHoursAgo
      );

      setData(filteredData.reverse()); // Display most recent data on the right
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000); // Refresh data every 2 minutes
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Text>Loading WETH Chart...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago',
    });
  };

  // Extract recent data
  const mostRecentEntry = data.length > 0 ? data[data.length - 1] : null;
  const mostRecentPrice = mostRecentEntry ? mostRecentEntry.price : 'N/A';
  const mostRecentTimestamp = mostRecentEntry
    ? formatDate(mostRecentEntry.timestamp)
    : 'N/A';

  // Prepare chart data
  const labels = data.map((entry) => formatDate(entry.timestamp));
  const wethPrices = data.map((entry) => entry.price);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'WETH Price (Last 24 Hours)',
        data: wethPrices,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          maxTicksLimit: 12,
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (USD)',
        },
      },
    },
  };

  return (
    <Box
      p={2}
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="md"
    >
      {mostRecentEntry && (
        <Box mb={2}>
          <Text fontSize="sm">
            <strong>Price:</strong> ${mostRecentPrice.toFixed(2)}
          </Text>
          <Text fontSize="sm">
            <strong>Timestamp:</strong> {mostRecentTimestamp}
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataTokenPriceWeth;
