import React, { useState, useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';

const DataChangeLimit = ({ onDataFetched }) => {
  const [changeLimit, setChangeLimit] = useState(null);
  const [lossLimit, setLossLimit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/trade_settings/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      if (result && result.data && result.data.length > 0) {
        // Sort the data by id in descending order (highest id first)
        const sortedData = result.data.sort((a, b) => b.id - a.id);

        // Get the most recent data object (highest id)
        const latestTradeSettings = sortedData[0];

        // Extract only loss_limit and change_limit
        setChangeLimit(`${latestTradeSettings.change_limit}`);
        setLossLimit(`${latestTradeSettings.loss_limit}`);

        // Pass to parent component
        if (onDataFetched) {
          onDataFetched({
            changeLimit: `${latestTradeSettings.change_limit}`,
            lossLimit: `${latestTradeSettings.loss_limit}`,
          });
        }
      } else {
        throw new Error('No data found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!changeLimit || !lossLimit) return <p>No data available</p>;

  return (
  null
  );
};

export default DataChangeLimit;
