import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Center, Box, Text, HStack } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DataRatio = () => {
  const [mostRecent, setMostRecent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/ratio_records/';

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result.data.length > 0) {
        // Set the most recent entry
        setMostRecent(result.data[result.data.length - 1]);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!mostRecent) return <p>No data available</p>;

  // Prepare data for the chart using only the most recent entry
  const labels = [new Date(mostRecent.timestamp).toLocaleString()]; // Label for the most recent timestamp
  const chartData = {
    labels: labels, // Use the timestamp of the most recent entry as the label
    datasets: [
      {
        label: 'Long Ratio',
        data: [mostRecent.long_ratio],
        backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Short Ratio',
        data: [mostRecent.short_ratio],
        backgroundColor: 'rgba(255, 99, 132, 0.5)', // Red
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        title: {
         // display: true,
        //  text: 'Timestamp',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Ratio',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `Ratio: ${(value * 100).toFixed(2)}%`; // Format tooltip value as percentage
          },
        },
      },
    },
  };

  return (
    <Box p={2}   backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {/* Display the most recent long/short ratio and timestamp above the chart */}
      {mostRecent && (
        <>
          
          <HStack>
          <Text fontSize='sm'>Long: {(mostRecent.long_ratio * 100).toFixed(2)}%</Text>
          <Text fontSize='sm'>Short: {(mostRecent.short_ratio * 100).toFixed(2)}%</Text>
          </HStack>
        </>
      )}
      
        <Bar data={chartData} options={chartOptions} />
      
    </Box>
  );
};

export default DataRatio;
