import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";

const FetchWethPriceBase = ({ onWethPriceUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { provider } = useContext(AppContext);
  const weth = '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB';

  // Function to fetch the WAVAX price
  const fetchWethPrice = async () => {
    try {
      // Initialize routerContract with the VaultPriceFeed contract
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address, // Ensure this address is correctly defined in your VaultPriceFeed.json
        VaultPriceFeed.abi,
        provider
      );

      // Fetch the price from the contract
      const acceptablePrice = await routerContract.getPriceV1(weth, false, true);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      const rawPrice = priceInWei.div(scaleFactor).toNumber() / 1000;

      // Format the price to two decimal places
      const formattedPrice = parseFloat(rawPrice.toFixed(2));

      
      // Update parent with the new formatted price
      if (onWethPriceUpdate) {
        onWethPriceUpdate(formattedPrice); // Pass the formatted price to parent component
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching WETH price:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWethPrice(); // Fetch on mount

    // Set an interval to fetch every 3 minutes (180,000 ms)
    const intervalId = setInterval(() => {
      fetchWethPrice();
    }, 180000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [onWethPriceUpdate]); // Dependency array to re-fetch if onWethPriceUpdate changes

  // No return statement or JSX in this component
};

export default FetchWethPriceBase;
