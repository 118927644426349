import { Tooltip, IconButton, Link } from "@chakra-ui/react";
import { FiDatabase } from "react-icons/fi";

const AccountDataLink = () => (
  <Tooltip label="View Account Data" aria-label="Data Tooltip" placement="top">
    <Link href="/collar1" isExternal>
      <IconButton
        colorScheme="blue"
        size="lg"
        variant="unstyled"
        icon={<FiDatabase />} // Changed to a 'data' icon
        aria-label="Account Data"
      />
    </Link>
  </Tooltip>
);

export default AccountDataLink;
