import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Box, Text } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataNetwork = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/network_data/";

  const fetchData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();

      // Filter only the last 6 hours of data
      const sixHoursAgo = Date.now() - 6 * 60 * 60 * 1000;
      const filteredData = result.data.filter(
        (entry) => new Date(entry.timestamp).getTime() >= sixHoursAgo
      );

      setData(filteredData.reverse()); // Reverse to show recent data on the right
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on initial render

    // Set up auto-refresh every 2 minutes for fast updates
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId); // Cleanup interval
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "2-digit", // Two-digit year format
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago", // Central Time Zone
    });
  };

  // Prepare chart data
  const labels = data.map((entry) => formatDate(entry.timestamp));
  const gasPriceData = data.map((entry) => entry.gas_price);

  // Get the most recent gas price
  const mostRecent = data.length > 0 ? data[data.length - 1] : null;

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Gas Price",
        data: gasPriceData,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.2)",
        tension: 0.3, // Smooth line
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw.toFixed(2)} Gwei`, // Format tooltips
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: { maxTicksLimit: 12 }, // Limit ticks for better readability
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Gas Price (Gwei)",
        },
      },
    },
  };

  return (
    <Box p={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {mostRecent && (
        <Box marginBottom={1}>
          <Text fontSize="sm">
            <strong>Gas Price:</strong> {mostRecent.gas_price.toFixed(2)} Gwei
            (as of {formatDate(mostRecent.timestamp)})
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataNetwork;
