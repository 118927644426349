import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import {
  injectedWallet,
  braveWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  trustWallet,
  uniswapWallet,
  phantomWallet,
} from "@rainbow-me/rainbowkit/wallets";
import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import App from "./App";

// Safeguard: Check for browser environment
if (typeof window === "undefined") {
  console.error("No browser environment detected. This app requires a browser.");
  throw new Error("No browser environment detected.");
}

// Safeguard: Check for WebSocket support
if (!("WebSocket" in window)) {
  console.error("WebSocket is not supported by this browser.");
}

const projectId = "f95a49f83bc1ded0c992842132cbb0a3";
const dappName = "BLINDFOLD";

const avalanche = {
  id: 43114,
  name: "Avalanche",
  network: "avalanche",
  rpcUrls: {
    public: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
    default: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://snowtrace.io" },
  },
  testnet: false,
};

const avalancheFujiTestnet = {
  id: 43113,
  name: "Avalanche Fuji Testnet",
  network: "fuji",
  rpcUrls: {
    public: { http: ["https://api.avax-test.network/ext/bc/C/rpc"] },
    default: { http: ["https://api.avax-test.network/ext/bc/C/rpc"] },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://testnet.snowtrace.io" },
  },
  testnet: true,
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [avalanche, avalancheFujiTestnet],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Popular",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      //walletConnectWallet({ projectId, chains }),
      braveWallet({ chains }),
      coinbaseWallet({ dappName, chains }),
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      uniswapWallet({ projectId, chains }),
      phantomWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try refreshing the page.</h1>;
    }
    return this.props.children;
  }
}

// Render the application
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          
          theme={lightTheme({
            accentColor: "#0052ff",
            accentColorForeground: "white",
            borderRadius: "large",
            fontStack: "system",
          })}
        >
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
