import { HStack, Box, Text } from "@chakra-ui/react";
import { formatAddress } from "../utils/formatMetamask";

import LogoutButton from "./wallet/LogoutButton";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";

function ActiveContractInfo() {
    const {contractAddress, contractName} = useContext(AppContext);
  return (
    <HStack gap={1} justify='right' >
      
        
        <Box
          w="12px"
          h="12px"
          borderRadius="50%"
          bg="green.600"
          display="inline-block"
        />
      
        <Text fontSize="sm" color="gray.700">
          {formatAddress(contractAddress)}
        </Text>
      

      
      <CopyToClipboardButton value={contractAddress} />

      
      <Text > | </Text> 

      
      <Text fontSize="sm">{contractName}</Text>

      {/* Logout button */}
      <LogoutButton />
    </HStack>
  );
}

export default ActiveContractInfo;
