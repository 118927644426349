import React, { useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Image, HStack, Text } from "@chakra-ui/react";
import DataTokenPriceWavax from "../api/DataTokenPriceWavax";
import DataTokenPriceWeth from "../api/DataTokenPriceWeth";
import DataTokenPriceBtc from "../api/DataTokenPriceBtc";

const TokenPriceTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Default to AVAX (index 0)

  return (
    <Box p={4} backgroundColor="white" borderRadius="md" boxShadow="md">
      <Tabs
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
        isLazy
        variant="enclosed"
        
      >
        {/* Tab Headers */}
        <TabList>
          <Tab>
          <HStack><Image src="./avax-logo.png" w={'20px'}/>
                <Text>AVAX</Text></HStack>
          </Tab>

          <Tab>
          <HStack><Image src="./eth.png" w={'20px'}/>
                <Text>ETH</Text></HStack>
          </Tab>
          <Tab>
          <HStack><Image src="./btc.png" w={'20px'}/>
                <Text>BTC</Text></HStack>
          </Tab>
        </TabList>

        {/* Tab Content */}
        <TabPanels>
          {/* AVAX Tab */}
          <TabPanel>
            <DataTokenPriceWavax />
          </TabPanel>

          {/* ETH Tab */}
          <TabPanel>
            <DataTokenPriceWeth />
          </TabPanel>

          {/* BTC Tab */}
          <TabPanel>
            <DataTokenPriceBtc />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TokenPriceTabs;
