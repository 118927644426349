import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

const DataCollarStatusMini = () => {
  const [mostRecentEvent, setMostRecentEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(120); // Timer starts at 120 seconds

  const apiUrl = 'https://blindfoldcapital.com/api/bot_status/';

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result.data && result.data.length > 0) {
        // Get the most recent event
        const mostRecent = result.data[result.data.length - 1];
        setMostRecentEvent(mostRecent);
      } else {
        setMostRecentEvent(null);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Timer countdown logic
  useEffect(() => {
    // Countdown every second
    const timerInterval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 120)); // Reset timer at 0
    }, 1000);

    // Auto-refresh every 2 minutes
    const fetchInterval = setInterval(() => {
      fetchData();
      setTimeLeft(120); // Reset timer after refresh
    }, 120000); // 2 minutes

    // Cleanup intervals
    return () => {
      clearInterval(timerInterval);
      clearInterval(fetchInterval);
    };
  }, []);

  // Initial data fetch on mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;
  if (!mostRecentEvent) return <Text>No data available</Text>;

  // Function to format the date and time without seconds
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <Box p={4} maxWidth="450px" backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      <Text fontSize="lg" fontWeight="bold">Bot 1</Text>
      <Text fontSize="sm" color="gray.700">
        Last Updated: {formatDate(mostRecentEvent.timestamp)}
      </Text>
      <Text fontSize="sm" overflow='auto'>
        Status: {mostRecentEvent.status || 'No status available'}
      </Text>
      {/**<Text fontSize="sm" color="blue.500" mt={2}>
        Next update in: {timeLeft} seconds
      </Text> */}
    </Box>
  );
};

export default DataCollarStatusMini;
