import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, HStack, Text } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataUsdcBalance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://blindfoldcapital.com/api/balances/";

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      // Filter for USDC balances
      const filteredData = result.data.filter((entry) => entry.token === "USDC");

      setData(filteredData.reverse()); // Reverse to display most recent on the right
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000); // Refresh every 2 minutes
    return () => clearInterval(intervalId); // Cleanup interval
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "2-digit", // Two-digit year
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Chicago", // Central Time Zone
    });
  };

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const balances = data.map((entry) => parseFloat(entry.balance).toFixed(2));

  const mostRecentEntry = data[0];
  const mostRecentBalance = mostRecentEntry ? parseFloat(mostRecentEntry.balance).toFixed(2) : "N/A";
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : "N/A";

  const chartOptions = {
    
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw} USDC`,
        },
      },
    },
    scales: {
      x: {
        type: "category",
        ticks: {
          maxTicksLimit: 12, // Limit number of X-axis labels for readability
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Balance (USDC)",
        },
        ticks: {
          callback: function (value) {
            return `$${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  const createChartData = (data, label, color) => ({
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        borderColor: color,
        backgroundColor: `${color}33`, // Add transparency to background color
        fill: true,
        tension: 0.3, // Smooth line
      },
    ],
  });

  return (
    <Box
      p={2}
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="md"
    >
      <HStack>
        <Text fontSize="sm">${mostRecentBalance} USDC</Text>
        {mostRecentTimestamp && (
          <Text fontSize="sm">Last Update: {mostRecentTimestamp}</Text>
        )}
      </HStack>
      <Line
        data={createChartData(balances, "USDC Balance", "blue")}
        options={chartOptions}
      />
    </Box>
  );
};

export default DataUsdcBalance;
