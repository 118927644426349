import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text, Center } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataCompound = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/compound_values/'; // Change to your collateral_used API endpoint

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Function to format the date and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  // Prepare data for the chart
  const labels = data.map((entry) => formatDate(entry.timestamp));
  const compoundData = data.map((entry) => parseFloat(entry.compound_values));

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Compound (USD)',
        data: compoundData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
        tension: 0.1, // For a smoother line
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Compound (USD)',
        },
      },
    },
  };

  // Extract the most recent recorded value
  const mostRecentEntry = data[data.length - 1];
  const mostRecentValue = mostRecentEntry ? mostRecentEntry.compound_values : null;
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : null;

  return (
    <Box p={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {/* Display the most recent value */}
      
          <Text fontSize="sm" >
            Most Recent Value: ${mostRecentValue && mostRecentValue.toFixed(2)}
          </Text>
          
      
      {/* Render the chart */}
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default DataCompound;
