import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataLiquidityWeth = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/liquidity_data/';

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Network response was not ok');

      const result = await response.json();

      // Filter data for the last 6 hours
      const sixHoursAgo = Date.now() - 6 * 60 * 60 * 1000;
      const filteredData = result.data.filter(
        (entry) => new Date(entry.timestamp).getTime() >= sixHoursAgo
      );

      setData(filteredData.reverse()); // Reverse for recent data on the right
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000); // Refresh every 2 minutes
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Chicago',
    });
  };

  const mostRecentEntry = data.length > 0 ? data[data.length - 1] : null;
  const mostRecentLongLiquidity = mostRecentEntry ? mostRecentEntry.weth_long_liquidity : 'N/A';
  const mostRecentShortLiquidity = mostRecentEntry ? mostRecentEntry.weth_short_liquidity : 'N/A';
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : 'N/A';

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const wethLongData = data.map((entry) => entry.weth_long_liquidity);
  const wethShortData = data.map((entry) => entry.weth_short_liquidity);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Long Liquidity',
        data: wethLongData,
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        fill: true,
        tension: 0.3,
      },
      {
        label: 'Short Liquidity',
        data: wethShortData,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          maxTicksLimit: 12, // Limit X-axis labels for readability
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Liquidity (USD)',
        },
      },
    },
  };

  return (
    <Box
      p={2}
      backgroundColor="gray.50"
      borderRadius="md"
      boxShadow="md"
    >
      {mostRecentEntry && (
        <Box mb={2}>
          <Text fontSize="sm">
            <strong>Long Liquidity:</strong> ${mostRecentLongLiquidity.toFixed(2)}
          </Text>
          <Text fontSize="sm">
            <strong>Short Liquidity:</strong> ${mostRecentShortLiquidity.toFixed(2)}
          </Text>
          <Text fontSize="sm">
            <strong>Timestamp:</strong> {mostRecentTimestamp}
          </Text>
        </Box>
      )}
      <Box width="100%" height="200px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataLiquidityWeth;
