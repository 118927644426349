import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  Center,
  VStack,
} from "@chakra-ui/react";
import CreateWallet from "./CreateWallet"; // Import your CreateWallet component
import DeployedWallets from "./DeployedWallets"; // Import your DeployedWallets component
import TradeSignalsTable from "../../blindfold-api/TradeSignalsTable";
import BtcSignalsTable from "../../blindfold-api/BtcSignalsTable";
import OptimalTradeConfig from "../../blindfold-api/OptimalTradeConfig";
import SignalsSection from "../SignalsSection";

const WalletTabs = () => {
  return (
    <Tabs size="md" variant='line' mt={1}>
      <TabList p={0}>
        <Tab>Home</Tab>

        <Tab>Create</Tab>
        <Tab>Load</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p={1}>
          <VStack justify="center" p={4}>
            <Image w="200px" src="./positionbalancer.png" />

            <SignalsSection/>
          </VStack>
        </TabPanel>

        <TabPanel p={0}>
          <Center>
            <Box p={4} maxWidth="400px">
              <CreateWallet />
            </Box>
          </Center>
        </TabPanel>
        <TabPanel p={0}>
          <Center>
            <Box p={4} maxWidth="400px">
              <DeployedWallets />
            </Box>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default WalletTabs;
