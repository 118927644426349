import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

function BlindfoldInfo() {
  return (
    <Box
      p={{ base: 6, md: 8 }} // Responsive padding
      maxW="container.md"
      mx="auto"
      bg={useColorModeValue("white", "gray.700")} // Light and dark mode support
      borderRadius="lg"
      boxShadow="lg"
    >
      {/* Main Heading */}
      <Heading
        size="lg"
        textAlign="center"
        mb={4}
     //   color={useColorModeValue("teal.600", "teal.300")}
      >
        Blindfold Balancer
      </Heading>

      {/* Overview */}
      <Text
        mb={6}
        lineHeight="taller"
        fontSize={{ base: "md", md: "lg" }}
        textAlign="justify"
    //    color={useColorModeValue("gray.600", "gray.300")}
      >
        Blindfold SmartContract wallet is secured by three EVM wallet private keys. 
        This contract interfaces a decentralized 
        perpetual cryptocurrency options exchange. A backend 
        trading application monitors token prices, balances, open exchange positions, 
        technical analysis indicators, and real-time trading parameter calculations.
      </Text>

      <Text
        mb={6}
        lineHeight="taller"
        fontSize={{ base: "md", md: "lg" }}
        textAlign="justify"
        color={useColorModeValue("gray.600", "gray.300")}
      >
        This is a private asset holding web3 application built on the Avalanche blockchain.  
      </Text>




    </Box>
  );
}

export default BlindfoldInfo;
