import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Text, Box } from '@chakra-ui/react';

const DataTradeSettings = ({ onDataFetched }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/trade_settings/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result && result.data && result.data.length > 0) {
        // Sort the data by the highest id or most recent timestamp
        const sortedData = result.data.sort((a, b) => {
          // Sort by id in descending order
          const idComparison = b.id - a.id;
          if (idComparison !== 0) return idComparison;

          // If ids are the same, compare by timestamp (most recent first)
          const timestampA = new Date(a.timestamp);
          const timestampB = new Date(b.timestamp);
          return timestampB - timestampA; // most recent timestamp first
        });

        // Get the most recent data object (highest id or latest timestamp)
        const latestTradeSettings = sortedData[0];
        setData(latestTradeSettings);

        // Call the callback function to pass data to parent
        if (onDataFetched) {
          onDataFetched(latestTradeSettings);
        }
      } else {
        throw new Error('No data found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!data) return <p>No data available</p>;

  const formatAsPercentage = (value) => `${(value * 100).toFixed(2)}%`;
  const formatDollar = (value) => `$${value.toFixed(2)}`;
  const formatAsMultiplier = (value) => `${value}x`;
  const formatMilliseconds = (value) => `${value / 1000}s`;

  const parameterMapping = {
    slippage: 'Slippage',
    min_starting_collateral: 'Min Starting Collateral',
    min_compound: 'Min Compound',
    change_limit: 'Change Limit',
    loss_limit: 'Loss Limit',
    reserve_delta: 'Reserve Delta',
    collar_delta: 'Collar Delta',
    compound_delta: 'Compound Delta',
    max_collateral: 'Max Collateral',
    min_usdc_balance: 'Min USDC Balance',
    min_avax_balance: 'Min AVAX Balance',
    topup_value: 'Top-up Value',
    min_liquidity: 'Min Liquidity',
    time_delay: 'Time Delay (s)',
    cooldown_period: 'Cooldown Period (ms)',
    database_sync: 'Database Sync',
    minStartingCollateral: 'Min Starting Collateral', // Added missing fields
    minCompound: 'Min Compound', // Added missing fields
    maxCollateral: 'Max Collateral', // Added missing fields
    minLiquidity: 'Min Liquidity', // Added missing fields
    timeDelay: 'Time Delay (s)', // Added missing fields
    databaseSync: 'Database Sync', // Added missing fields
    minAvaxBalance: 'Min AVAX Balance', // Added missing fields
    topupValue: 'Top-up Value', // Added missing fields
    wavax_long_enabled: 'WAVAX Long Enabled', // Added missing fields
    wavax_short_enabled: 'WAVAX Short Enabled', // Added missing fields
    weth_long_enabled: 'WETH Long Enabled', // Added missing fields
    weth_short_enabled: 'WETH Short Enabled', // Added missing fields
    btc_long_enabled: 'BTC Long Enabled', // Added missing fields
    btc_short_enabled: 'BTC Short Enabled', // Added missing fields
    timestamp: 'Timestamp',
  };

  const formattedData = Object.keys(data)
    .filter((key) => key !== 'id') // Exclude 'id' from displaying
    .map((key) => {
      let value = data[key];

      if (['collar_delta', 'compound_delta', 'reserve_delta'].includes(key)) {
        value = formatAsPercentage(value);
      }

      if (['change_limit', 'loss_limit'].includes(key)) {
        value = `${value}%`;
      }

      if (
        [
          'min_usdc_balance',
          'min_starting_collateral',
          'min_compound',
          'max_collateral',
          'topup_value',
        ].includes(key)
      ) {
        value = formatDollar(value);
      }


      if (['time_delay'].includes(key)) {
        value = `${value}s`;
      }

      if (key === 'cooldown_period') {
        value = formatMilliseconds(value);
      }

      if (key === 'database_sync') {
        value = value === '1' ? 'Enabled' : 'Disabled';
      }

      return [parameterMapping[key], value];
    });

  return (
    <Box maxWidth="360px">
      <Grid
        templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={0}
        gridColumnGap={1}
      >
        {formattedData.map(([parameter, value], index) => (
          <React.Fragment key={index}>
            <GridItem>
              <Text fontWeight="bold">{parameter}:</Text>
            </GridItem>
            <GridItem>
              <Text>{value}</Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default DataTradeSettings;
