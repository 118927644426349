import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Center, Box, Text, HStack } from "@chakra-ui/react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataDeltaProfits = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalDelta, setTotalDelta] = useState(0);
  const [mostRecentTimestamp, setMostRecentTimestamp] = useState(null);

  const apiUrl = "https://blindfoldcapital.com/api/delta_profits/";

  // Function to format the date and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result.data);

      // Calculate the total execution fees
      const total = result.data.reduce(
        (sum, entry) => sum + parseFloat(entry.delta_profit),
        0
      );
      setTotalDelta(total);

      // Get the most recent timestamp
      if (result.data.length > 0) {
        const mostRecentEntry = result.data[result.data.length - 1];
        setMostRecentTimestamp(formatDate(mostRecentEntry.timestamp));
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Prepare data for the chart
  const labels = data.map((entry) => formatDate(entry.timestamp));
  const executionFeesData = data.map((entry) =>
    parseFloat(entry.delta_profit)
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Execution Fees (AVAX)",
        data: executionFeesData,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.2)",
        fill: true,
        tension: 0.1, // For a smoother line
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Execution Fee (AVAX)",
        },
      },
    },
  };

  return (
    
 
        <Box p={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
          <HStack>
            <p>Total Delta: {totalDelta.toFixed(3)} </p>
            {mostRecentTimestamp && (
            <Text fontSize="sm" color="gray.500">
              Last Update: {mostRecentTimestamp}
            </Text>
          )}
    
          </HStack>


    
    
        <Line data={chartData} options={chartOptions} />
        </Box>
    
  );
};

export default DataDeltaProfits;
