import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Text, Heading, Spinner, useToast } from "@chakra-ui/react";

const SignalDisplay = () => {
  const [signal, setSignal] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const fetchSignal = async () => {
    setLoading(true);
    try {
      const apiUrl = "https://blindfoldcapital.com/api/ta/trade_signals";
      const response = await axios.get(apiUrl);

      if (response.data && Array.isArray(response.data.data)) {
        const latestSignal = response.data.data
          .filter((entry) => entry.interval === "1m")
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];
        setSignal(latestSignal?.signal || "N/A");
      } else {
        throw new Error("Invalid API response format");
      }
    } catch (error) {
      toast({
        title: "Error fetching signal",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSignal();
  }, []);

  return (
    <Box p={1} >
      
      {loading ? (
        <Spinner size="sm" />
      ) : (
        <Text fontSize="sm" fontWeight="bold">
          {signal || "No data available"}
        </Text>
      )}
    </Box>
  );
};

export default SignalDisplay;