import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import DataTokenPriceBtc from "../api/DataTokenPriceBtc";
import DataTokenPriceWeth from "../api/DataTokenPriceWeth";
import DataTokenPriceWavax from "../api/DataTokenPriceWavax";
import DataLiquidityBtc from "../api/DataLiquidityBtc";
import DataLiquidityWeth from "../api/DataLiquidityWeth";
import DataLiquidityWavax from "../api/DataLiquidityWavax";
import DataNetwork from "../api/DataNetwork";

import DataBlockNumber from "../api/DataBlockNumber";
import DataIndicators from "../api/DataIndicators";
import DataWavaxBollinger from "../api/DataWavaxBollinger";
import WavaxRsiIndicator from "../api/WavaxRsiIndicator";
import DataWavaxRsi from "../api/DataWavaxRsi";
import DataWavaxSma from "../api/DataWavaxSma";
import TokenPriceTabs from "./TokenPriceTabs";


const DataTabs = () => {
  const [marketTabIndex, setMarketTabIndex] = useState(0);

  return (
    <Tabs variant='line' p={0} size={'sm'} >
      <TabList>
        <Tab>Network</Tab>
        <Tab>Price</Tab>
        
        {/**<Tab>Expenditures</Tab> */}
        <Tab>Liquidity</Tab>
        
      </TabList>

      <TabPanels>
      <TabPanel >
          
          {/**<Box bg="white" m={1} p={1}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Current Runtime
            </Text>
            <DataRuntime />
          </Box> */}

          
            <Box >
              
            <DataNetwork />
          
            </Box>

          
                  

          {/**<Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Block
            </Text>
            <DataBlockNumber />
          </Box> */}
        </TabPanel>
        <TabPanel>
      <TokenPriceTabs/>
        </TabPanel>

    
      
        <TabPanel  >
                
                <Box >
                  <Text fontSize="md" fontWeight="bold" >
                    WAVAX Liquidity (GMX V1)
                  </Text>
                  <DataLiquidityWavax />
                </Box>
                
                <Box>
                  <Text fontSize="md" fontWeight="bold" mt={2}>
                    WETH Liquidity (GMX V1)
                  </Text>
                  <DataLiquidityWeth />
                </Box> 
                
              </TabPanel>
        {/**<TabPanel>
            <Text>Events</Text>

            <Box p={2}>
            <WithdrawalRequests />
            </Box>

            <Box p={2}>
                <ApprovalGranted />
            </Box>

            <Box p={2}>
                <TokenDeposits />
            </Box>

            <Box p={2}>
                <TokenWithdrawalRequests />
            </Box>

            <Box p={2}>
                <RecentPositionIncreases / >
            </Box>
        </TabPanel> */}

        
      </TabPanels>
    </Tabs>
  );
};

export default DataTabs;
