import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Center, Text, Box, HStack } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataCollateralUsed = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/collateral_used/'; // Change to your collateral_used API endpoint

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text fontSize='sm'>Loading...</Text>;
  if (error) return <Text fontSize='sm'>Error: {error}</Text>;

  // Function to format the date and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Calculate total collateral
  const getTotalCollateral = () => {
    return data.reduce((total, entry) => total + parseFloat(entry.collateral_amount), 0);
  };

  // Calculate average collateral
  const getAverageCollateral = () => {
    const total = getTotalCollateral();
    return data.length ? (total / data.length) : 0;
  };

  // Get the most recent timestamp
  const getMostRecentTimestamp = () => {
    if (data.length === 0) return null;
    const mostRecentEntry = data[data.length - 1];
    return formatDate(mostRecentEntry.timestamp);
  };

  // Prepare data for the chart
  const labels = data.map(entry => formatDate(entry.timestamp));
  const collateralData = data.map(entry => parseFloat(entry.collateral_amount));

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Collateral Used (USD)',
        data: collateralData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
        tension: 0.1, // For a smoother line
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Collateral Used (USD)',
        },
      },
    },
  };

  return (
    <div>
      
      <Box p={2}   backgroundColor="gray.50" borderRadius="md" boxShadow="md">
         <HStack>
         <Text fontSize='sm'>
            Total: ${getTotalCollateral().toFixed(2)}
          </Text>
          <Text fontSize='sm'>
            Avg. : ${getAverageCollateral().toFixed(2)}
          </Text>
         
         </HStack>
      
      
        <Line data={chartData} options={chartOptions} />
        </Box>
    </div>
  );
};

export default DataCollateralUsed;
