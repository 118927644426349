import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";

const FetchWavaxPriceBase = ({ onWavaxPriceUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { provider } = useContext(AppContext);
  const wavax = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7';

  // Function to fetch the WAVAX price
  const fetchWavaxPrice = async () => {
    try {
      // Initialize routerContract with the VaultPriceFeed contract
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address, // Ensure this address is correctly defined in your VaultPriceFeed.json
        VaultPriceFeed.abi,
        provider
      );

      // Fetch the price from the contract
      const acceptablePrice = await routerContract.getPriceV1(wavax, false, true);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      const rawPrice = priceInWei.div(scaleFactor).toNumber() / 1000;

      // Format the price to two decimal places
      //const formattedPrice = Math.round(rawPrice * 100) / 100;

      
      const formattedPrice = parseFloat(rawPrice.toFixed(2));

      // Update parent with the new formatted price
      if (onWavaxPriceUpdate) {
        onWavaxPriceUpdate(formattedPrice); // Pass the formatted price to parent component
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching WAVAX price:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWavaxPrice(); // Fetch on mount

    // Set an interval to fetch every 3 minutes (180,000 ms)
    const intervalId = setInterval(() => {
      fetchWavaxPrice();
    }, 180000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [onWavaxPriceUpdate]); // Dependency array to re-fetch if onWavaxPriceUpdate changes

  // No return statement or JSX in this component
};

export default FetchWavaxPriceBase;
